<template>
    <div class="distributor m-p-t-47">
        <!-- //手机注册 -->
        <!-- <phone-register ref="dataFrom" :form="form" @tapNext="handleNext" v-if="active === 0" /> -->
        <!-- // 企业注册逐渐 -->
        <firm-register ref="dataFrom" :form="form" @tapNext="handleNext"/>

        <el-button class="btn" style="margin-top: 12px;" type="primary" @click="next">提交</el-button>
    </div>
</template>

<script>
/***
 * @Author zhd
 * @Date 2021-10-22
 * @desc 企业注册 
 */
import { reactive, ref } from 'vue'
// import phoneRegister from '../../components/register/phoneRegister.vue'
import firmRegister from '../../components/register/firmRegister.vue'



export default {
    components: {
        // phoneRegister,
        firmRegister
    },
    setup(){
        const active = ref(0)
        const form = reactive({
            realName : '', // 名字
            bindPhone: '', // 联系电话
            idCard: '', // 身份证
            jobPost: '', // 职位
            jobRole: '', // 角色
            //企业
            enterpriseName: '', // 名称
            establishedTime: '', // 创立时间
            registeredCapital: '', // 注册资本：
            socialCreditCode: '', // 社会信用代码：
            legalPersonName: '', // 法定代表人：
            enterpriseAddr: '', // 地址
            userId: '', // 注册成功的userid
            enterpriseLicense: '',
        })
        const dataFrom = ref('')
        const next = () => {
            dataFrom.value.getData()


            //  if (active.value++ > 2) active.value = 0
        }
        const handleNext = (v) => {
            for (const k in v) {
                if (form[k]) {
                    form[k] = v[k]
                }
            }
           if (active.value++ > 2) active.value = 0
        }

        return {
            dataFrom,// 表单ref
            form, //表单数据
            active, // 步骤选中
            next, // 步骤方法
            handleNext,
        }
    }
}
</script>
<style lang="less" scoped>
    .m-p-t-47{
        padding-top: 47px;
    }
    .distributor{
        width: 100%;
        margin: 0 auto;
    }
    .btn{
        width: 400px;
        height: 50px;
        display: block;
        margin: 0 auto;
    }
</style>