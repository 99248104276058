<template>
  <div class="customer">
    <el-form ref="Form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="企业名称：">
        <div class="flex">
          <el-input
            @input="firmNameSearch"
            v-model="firmName"
            placeholder="可不选"
          ></el-input>
          <div class="realname-text">
            <span>没找到企业？请点击</span>
            <span class="red" @click="showFirm = !showFirm">认证企业</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="营业执照：" prop="enterpriseLicense">
        <div class="upload-one-img">
          <div class="avatar-upload" :style="{ width: '100px', height: '100px' }">
            <div class="avatar" v-if="dataForm.enterpriseLicense">
              <el-image
                class="img advertising-img"
                :src="dataForm.enterpriseLicense"
                fit="contain"
              />
              <div class="shade-box">
                <el-upload
                  class="upload-demo"
                  accept=".jpg,.png,.jpeg"
                  action="action"
                  :show-file-list="false"
                  :http-request="uploadImg"
                  :before-upload="beforeAvatarUpload"
                >
                  <i class="iconfont iconbianji" type="primary" />
                </el-upload>
                <i class="iconfont iconshanchu" @click="removeImg" />
              </div>
            </div>
            <el-upload
              class="avatar-uploader"
              accept=".jpg,.png,.jpeg"
              action="action"
              :show-file-list="false"
              :http-request="uploadImg"
              :before-upload="beforeAvatarUpload"
              v-else
            >
              <i
                class="el-icon-plus avatar-uploader-icon"
                :style="{ lineHeight: '20px' }"
              />
            </el-upload>
          </div>
        </div>
      </el-form-item>
      <div class="firm-form">
        <template v-if="showFirm">
          <el-form-item label="公司名称：" prop="enterpriseName">
            <el-input
              v-model="dataForm.enterpriseName"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="社会信用代码：" prop="socialCreditCode">
            <el-input
              v-model="dataForm.socialCreditCode"
              placeholder="请输入社会信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="住址：" prop="enterpriseAddr">
            <el-input
              v-model="dataForm.enterpriseAddr"
              placeholder="请输入住址"
            ></el-input>
          </el-form-item>
          <el-form-item label="法定代表人：" prop="legalPersonName">
            <el-input
              v-model="dataForm.legalPersonName"
              placeholder="请输入法定代表人"
            ></el-input>
          </el-form-item>
          <el-form-item label="成立日期：" prop="establishedTime">
            <el-date-picker
              v-model="dataForm.establishedTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="注册资本：" prop="registeredCapital">
            <el-input
              v-model="dataForm.registeredCapital"
              placeholder="请输入注册资本"
            ></el-input>
          </el-form-item>
        </template>

        <el-form-item label="你的名字：" prop="realName">
          <el-input
            v-model="dataForm.realName"
            placeholder="请输入你的名字"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="bindPhone">
          <el-input
            v-model="dataForm.bindPhone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码：" prop="mobilePhone">
          <el-input
            v-model="dataForm.idCard"
            placeholder="请输入身份证号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司职位：">
          <el-select v-model="dataForm.jobPost" placeholder="请选择公司职位">
            <el-option label="Zone one" value="shanghai"></el-option>
            <el-option label="Zone two" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作角色：">
          <el-select v-model="dataForm.jobRole" placeholder="请选择工作角色">
            <el-option label="Zone one" value="shanghai"></el-option>
            <el-option label="Zone two" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邀请人：">
          <span>张华东</span>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
/***
 * @Author zhd
 * @Date 2021-10-22
 * @desc 企业认证组件
 */
import { toRefs, ref } from "vue";
import store from "@/store.js"; //引入状态管理模块
import { ElMessage } from "element-plus";
function MESSAGE(type = "erroe", title = "出错了") {
  ElMessage[type](title);
  return false;
}
const rules = {
  realName: [
    {
      required: true,
      message: "名字不能为空",
      trigger: "blur",
    },
  ],
  bindPhone: [
    {
      required: true,
      message: "手机号不能为空",
      trigger: "blur",
    },
    {
      pattern: /(^1[3|4|5|7|8][0-9]{9}$)/,
      transform(value) {
        // 先将值转换成字符串
        return String(value);
      },
      message: "请输入有效的手机号码",
      trigger: ["blur"],
    },
  ],
  idCard: [
    {
      required: true,
      message: "身份证不能为空",
      trigger: "blur",
    },
  ],
  jobPost: [
    {
      required: true,
      message: "职位不能为空",
      trigger: "blur",
    },
  ],
  jobRole: [
    {
      required: true,
      message: "角色不能为空",
      trigger: "blur",
    },
  ],
};
const firm = {
  enterpriseName: [
    {
      required: true,
      message: "企业名称不能为空",
      trigger: "blur",
    },
  ],
  establishedTime: [
    {
      required: true,
      message: "企业创立时间不能为空",
      trigger: "blur",
    },
  ],
  registeredCapital: [
    {
      required: true,
      message: "企业注册资本不能为空",
      trigger: "blur",
    },
  ],
  socialCreditCode: [
    {
      required: true,
      message: "企业社会信用代码不能为空",
      trigger: "blur",
    },
  ],
  legalPersonName: [
    {
      required: true,
      message: "企业法定代表人不能为空",
      trigger: "blur",
    },
  ],
  enterpriseAddr: [
    {
      required: true,
      message: "企业地址不能为空",
      trigger: "blur",
    },
  ],
};
let FILEOBJ = {};
export default {
  name: "FirmRegister",
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["tapNext"],
  setup(props, contxt) {
    const { form: dataForm } = toRefs(props);
    const Form = ref(null); // 表单ref
    const showFirm = ref(false); // 是否显示企业信息
    const firmName = ref("");

    const getData = () => {
      // if(!checked1.value) return ElMessage.warning('请勾选条款')
      Form.value.validate((valid) => {
        console.log(valid);
        if (valid) {
          contxt.emit("tapNext", dataForm);
        } else {
          //  ElMessage.warning('请勾选条款')
          return false;
        }
      });
    };
    // 图片上传之前
    const beforeAvatarUpload = (file) => {
      console.log(file, "file");
      if (!/\.(jpg|jpeg|png)$/i.test(file.name)) {
        // 文件类型过滤
        MESSAGE("warning", "文件格式只支持jpg|jpeg|png");
        return false;
      } else if (file.size > 2 * 1024 * 1024) {
        // 文件大小过滤
        MESSAGE("warning", "文件大小最大支持2M");
        return false;
      } else {
        FILEOBJ = file;
        return true;
      }
    };
    // 图片上传
    const uploadImg = (e) => {
      const { file } = e;
      let form = new FormData();
      form.append("file", file);
      const data = {
        form,
        type: "OTHER",
      };
      store.dispatch("loginModule/uploadReq", data).then((res) => {
        console.log(res);
        let { code } = res.data;
        if (code === "0") {
          dataForm.value.enterpriseLicense = URL.createObjectURL(FILEOBJ);
          console.log(dataForm.enterpriseLicense);
        }
      });
    };
    // 删除图片
    const removeImg = () => {
      dataForm.enterpriseLicense = "";
      FILEOBJ = {};
    };

    const firmNameSearch = (e) => {
    //   store.dispatch("loginModule/searchEnterprise", e);
    };

    return {
      rules, // 验证规则
      dataForm, // 表单数据
      Form, // 表单ref
      showFirm, // 企业表单显示
      firmName, // 根据名称查企业

      firmNameSearch, //企业名字搜索
      beforeAvatarUpload, // 上传之前
      uploadImg, // 上传营业执照
      removeImg, // 删除图片
      getData,
    };
  },
};
</script>
<style lang="less" scoped>
.customer {
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  max-height: 400px;
  overflow: auto;
  padding: 0 10px;
  box-sizing: border-box;
  .verificationCode {
    cursor: pointer;
  }
  .firm-form {
    display: grid;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .realname-text {
    width: 60%;
    margin-left: 20px;
    color: #0ea1e0;
    .red {
      color: #ff0000;
      text-decoration: underline;
      // outline-bottom: 1px solid #ff0000;
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.upload-one-img {
  .avatar-upload {
    .avatar-uploader {
      width: 100%;
      height: 100%;
      .el-upload {
        width: 100%;
        height: 100%;
        border: 1px dashed #d9d9d9;
        cursor: pointer;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100%;
        height: 100%;
        line-height: 140px;
        text-align: center;
        color: @dh-color;
      }
    }
    .avatar {
      width: 100%;
      height: 100%;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
      .shade-box {
        width: 100%;
        height: 100%;
        line-height: 140px;
        position: absolute;
        top: 0;
        background-color: #000;
        opacity: 0.6;
        display: none;
        align-items: center;
        justify-content: center;
        i {
          font-size: 16px;
          color: @dh-color;
          cursor: pointer;
        }
        .upload-demo {
          margin-right: 10px;
        }
      }
      &:hover .shade-box {
        display: flex;
      }
    }
  }
}
</style>